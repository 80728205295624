import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { MIN_DESKTOP_WIDTH } from '@/constants/common';

import { triggerGTAGInternalNavEvent } from '@/utils/analyticTracks';

import { RoutesEnum } from '../../../../enums/routes.enum';
import { useWindowSize } from '../../../../helpers/useWidth';
import NavigationButtons from '../../../NavigationButtons';
import NavigationLink from '../../../NavigationButtons/Components/NavigationLink';

import { WrapperNav } from './Top5Navigation.styled';
import { PicksHomeNavigationProps } from './types';

const To5Navigation: React.FC<PicksHomeNavigationProps> = ({
  children,
  currentPage,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width > MIN_DESKTOP_WIDTH;

  const BUTTONS = [
    // TODO
    // {
    //   to: RoutesEnum.rankings,
    //   GTAGLink: 'https://atpgamezone.com/ranking',
    //   selected: currentPage === 'top5',
    //   label: 'Top 5 Performers',
    // },
    {
      to: RoutesEnum.calendar,
      GTAGLink: 'https://atpgamezone.com/calendar',
      selected: currentPage === 'calendar',
      label: 'Calendar',
    },
  ];

  return (
    <>
      {isDesktop ? (
        <WrapperNav>
          <NavigationButtons>
            {BUTTONS.map((button, index) => (
              <Fragment key={index}>
                <NavigationLink
                  onClick={() => triggerGTAGInternalNavEvent(button.GTAGLink)}
                  selected={button.selected}
                  to={button.to}
                >
                  {button.label}
                </NavigationLink>
              </Fragment>
            ))}
          </NavigationButtons>
          {children}
        </WrapperNav>
      ) : (
        <>
          <NavigationButtons>
            {BUTTONS.map((button, index) => (
              <Fragment key={index}>
                <NavigationLink
                  onClick={() => triggerGTAGInternalNavEvent(button.GTAGLink)}
                  selected={button.selected}
                  to={button.to}
                >
                  {button.label}
                </NavigationLink>
              </Fragment>
            ))}
          </NavigationButtons>
          {children}
        </>
      )}
    </>
  );
};

export default To5Navigation;
