import axios from 'axios';

import { localStorageGet, localStorageSet } from '@/utils/localStorage';

// localStorageSet("jwt", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5LCJpYXQiOjE2NzMwMjMxOTUsImV4cCI6MTY3Mzg4NzE5NX0.UUSQ6BF6phWIdoz4AsISvk6pRIO_820vI2V007IzUv4")
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(function (config: any) {
  const token = localStorageGet('jwt');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
    }
  },
);

interface LoginResponse {
  token: string;
  location_country: string;
  location_region: string;
  favourite_team_league_id: number;
}

export const getJWTToken = async (token: string) => {
  try {
    // const code = localStorage.getItem('token');
    // const token = code && JSON.parse(code);
    const jwt = await instance.post<LoginResponse>('/login', {
      token: token,
    });
    const location = {
      country: jwt.data.location_country,
      region: jwt.data.location_region,
    };
    localStorageSet('jwt', jwt.data.token);
    localStorageSet('location', location);
    localStorageSet(
      'favourite_team_league_id',
      jwt.data.favourite_team_league_id,
    );
  } catch {
    window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
    // window.location.replace(`https://low6-atp-auth-dev.netlify.app/login`);
  }
};

export type League = {
  league_id: number;
  title: string;
  code: string;
  league_type: 'CUSTOM' | 'FRIEND';
  league_image?: string;
  is_owner: boolean;
  rank: number;
};

interface GetUserLeagues {
  leagues: League[];
}

export const getUserLeagues = async () => {
  const { data } = await instance.get<GetUserLeagues>(
    '/leagues?include_rank=true',
  );
  return data;
};

interface CheckLeagueName {
  exists: boolean;
}
export const checkLeagueName = async (name: string) => {
  const { data } = await instance.get<CheckLeagueName>(
    `/league-name/exists?title=${name}`,
  );
  return data;
};

interface CreateLeague {
  code: string;
}

export const createLeague = async (title: string) => {
  const { data } = await instance.post<CreateLeague>('/leagues', { title });
  return data;
};

interface JoinLeague {
  success: boolean;
}

export const joinLeague = async (code: string) => {
  const { data } = await instance.post<JoinLeague>('/leagues/join', { code });
  return data;
};

export const leaveLeague = async (league_id: number) => {
  const { data } = await instance.post<JoinLeague>('/leagues/leave', {
    league_id,
  });
  return data;
};

interface DeleteUserFromLeague {
  success: boolean;
}

export interface ISelection {
  question_id: number;
  option_id: number | string;
  type?: string;
  bonus_selection_value?: string;
}

export const deleteUserFromLeague = async (
  league_id: number | string,
  user_id: number,
) => {
  const { data } = await instance.delete<DeleteUserFromLeague>(
    `/leagues/${league_id}/user`,
    {
      data: { user_id },
    },
  );

  return data;
};

export const deleteLeague = async (league_id: number) => {
  const { data } = await instance.delete<DeleteUserFromLeague>(
    `/leagues/${league_id}`,
  );
  return data;
};

export const getSelections = async () => {
  const { data } = await instance.get(
    '/pickems/1/selections?user_id=1&include_stats=true',
  );
  return data;
};

export interface IUserPickemSelections {
  selections: Array<any>;
  stats: {
    avg: number;
    max: number;
  };
}

export const getUserPickemSelections = async (
  userId: string,
  pickemId: string,
): Promise<IUserPickemSelections> => {
  const { data } = await instance.get(
    `/pickems/${pickemId}/selections?user_id=${userId}&include_stats=true`,
  );
  return data;
};
export const getTop5Players = async (): Promise<IUserPickemSelections> => {
  const { data } = await instance.get('pickems/top-five');
  return data;
};
export const getTop5PlayersAll = async (): Promise<IUserPickemSelections> => {
  const { data } = await instance.get('pickems/top-five-all');
  return data;
};

export const postSelections = async (
  pickemId: number,
  selections: Array<ISelection>,
) => {
  const data = await instance.post(`/pickems/${pickemId}/selections`, {
    selections,
  });
  return data;
};
